/// <reference path="_variables.scss" />

.main-content {
    .section-block {
        padding: $main-content-padding-top 0;

        @media only screen and (min-width: 1024px) {
            padding: ($main-content-padding-top*2) 0;
        }

        &:first-of-type,
        &-no-padding-top {
            padding-top: 0;
        }

        &-primary {
            background-color: $section-block-primary-bg-color;
            color: $section-block-primary-font-color;

            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                color: $section-block-primary-font-color;
            }
        }

        &-secondary {
            background-color: $section-block-secondary-bg-color;
            color: $section-block-secondary-font-color;

            a {
                color: $section-block-secondary-link-color;
            }

            h1, h2, h3, h4, h5, h6,
            .h1, .h2, .h3, .h4, .h5, .h6 {
                color: $section-block-secondary-font-color;
            }
        }
    }

    .article-content {
        // remove padding on the article intor so we don't get a double space as the grid is ouside of this selector
        // the following css should basically restore the standard grid padding on 'section' unless the first grid section has a background class set
        .section-block {
            &:first-of-type {
                padding-bottom: 0;
            }
        }

        .grid-area {
            // now restore grid settings after the above has knocked it all out of alignment
            .section-block {
                &:first-of-type {
                    padding: ($main-content-padding-top*2) 0;
                }
            }
            // if the first section block has a background class set then undo the above fix
            [class*='section-block-']:first-of-type {
                margin-top: $main-content-padding-top;

                @media only screen and (min-width: 1024px) {
                    margin-top: ($main-content-padding-top*2);
                }
            }
        }
    }
}