/// <reference path="_variables.scss" />


.articles {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.article-content {
    overflow-wrap: anywhere;

    img {
        max-width: 100%;

        @media only screen and (max-width: 600px) {
            height: auto;
            margin-bottom: 1rem;
        }
    }

    .article-category-link {
        background-color: #F6F6F6;
        border: 1px solid #dedede;
        padding: .5rem;
        color: #000;
        margin-right: .5rem;
        display: inline-block;

        &:hover {
            text-decoration: none;
            background-color: #dedede;
        }
    }

    time {
        &.bold{
            font-weight:bold;
        }
        &.text-end {
            display: block;
        }
    }
}
